
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { createTheme } from "@mui/material";
import { NextComponentType } from "next";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import { Suspense } from "react";
import dynamic from "next/dynamic";
const ItemSpinner = dynamic(() => import("../../@core/components/common/widgets/itemSpinner"), {
    ssr: false
});
const ThemeProvider = dynamic(() => import("@mui/material").then((module) => module.ThemeProvider), {
    ssr: false
});
const SessionProvider = dynamic(() => import("next-auth/react").then((module) => module.SessionProvider), {
    ssr: false
});
const AuthMiddleware = dynamic(() => import("../../@core/auth").then((module) => module.default), {
    ssr: false
});
const CssBaseline = dynamic(() => import("@mui/material/CssBaseline").then((module) => module.default), {
    ssr: false
});
const customTheme = createTheme({
    typography: {
        fontFamily: `"Open-Sans", "Helvetica", "Arial", sans-serif`
    },
    palette: {
        secondary: {
            main: "#3B97DE"
        },
        primary: {
            main: "#0A2C58"
        }
    }
});
//Add custom appProp type then use union to add it
type CustomAppProps = AppProps & {
    Component: NextComponentType & {
        auth?: boolean;
    }; // add auth type
};
const App = ({ Component, pageProps }: CustomAppProps) => {
    return (<>
      <Suspense fallback={<ItemSpinner loading={true}/>}>
        <ThemeProvider theme={customTheme}>
          <SessionProvider session={pageProps.session}>
            <CssBaseline />
            {Component.auth ? (<AuthMiddleware>
                <Component {...pageProps}/>
              </AuthMiddleware>) : (<Component {...pageProps}/>)}
          </SessionProvider>
        </ThemeProvider>
      </Suspense>
    </>);
};
export async function getServerSideProps({ req, res }: any) {
    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');
    return {
        props: {}
    };
}
const __Next_Translate__Page__18bb33400c2__ = App;

    export default __appWithI18n(__Next_Translate__Page__18bb33400c2__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  